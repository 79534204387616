<template>
	<div style="background-color: #fafafa" class="dexWrapper">
		<div class="dexBox">
			<div class="flex align_items_e dexval">
				<div># {{ $route.query.name }} #</div>
				<div>(共{{ total }}条内容)</div>
			</div>
		</div>
		<div
			class="Main width_1200 margin_auto flex justify_content_sb dexBoxm"
			style="padding-bottom: 20px"
		>
			<div class="MainLeft">
				<div class="dexHeader flex align_items_c">
					<div class="headerActice"> 相关报道 </div>
				</div>
				<div style="padding: 20px 20px">
					<div
						class="wzitem flex align_items_c pointer"
						:class="{ wzitemImageNull: item.pic == null || item.pic == '' }"
						v-for="(item, index) in TicketDetails"
						:key="index"
						@click="
							$router.push({
								path: item.type == 1 ? '/gw_detail' : '/kuaixun_wz',
								query: { id: item.id },
							})
						"
					>
						<img
							:src="item.pic"
							:key="item.pic"
							alt=""
							v-if="item.pic != null && item.pic != ''"
						/>

						<div
							class="wzitemL"
							:class="{ wzitemLImageNull: item.pic == null || item.pic == '' }"
						>
							<div class="wzitemLTitle">
								<div
									style="color: #000000; font-weight: bold"
									class="ellipsis_one"
									>{{ item.title }}</div
								>
								<div>{{ item.illustrate }}</div>
							</div>
							<div class="wzitemLTime flex align_items_c justify_content_sb">
								<div
									class="wzitemLTime_l"
									v-time="item.release_time"
									v-if="item.release_time != null"
								></div>
							</div>
						</div>
					</div>
					<more @infinite="loadMore" v-if="tag"></more>
				</div>
			</div>
			<div class="MainRight">
				<div class="MainRight_1 hidden_scroll">
					<div class="dexHeader flex align_items_c">
						<div class="headerActice"> 相关快讯 </div>
					</div>
					<div class="steps kxsteps">
						<el-steps direction="vertical" :active="1" style="height: 95%">
							<el-step v-for="(item, index) in flash_list" :key="index">
								<template slot="description">
									<div class="stepsVal">{{ item.title }}</div>
									<div
										class="flex justify_content_sb align_items_c"
										style="margin-top: 12px"
									>
										<div
											class="timeOut"
											v-time="item.release_time"
											v-if="item.release_time != null"
										>
										</div>
										<div class="flex justify_content_sb align_items_c"> </div>
									</div>
								</template>
							</el-step>
						</el-steps>
					</div>
				</div>

				<div class="MainRight_6 eventWraper">
					<div class="MainRight_6_title">24小时热文</div>
					<othresou></othresou>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getTicketDetails, searchMore } from "@/api/home";
export default {
	name: "",
	data() {
		return {
			property: "value",
			TicketDetails: [],
			total: "",
			flash_list: [],
			page: 1,
			size: 25,
			tag: false,
		};
	},
	created() {
		this.getTicketDetails(this.$route.query.id);
		this.searchMore();
	},
	methods: {
		loadMore() {
			this.page++;
			this.getTicketDetails(this.$route.query.id);
		},
		getTicketDetails(id) {
			getTicketDetails({ id: id, page: this.page, size: this.size })
				.then((res) => {
					if (res.code == 200) {
						this.TicketDetails = this.TicketDetails.concat(res.data.data.list);
						res.data.data.list.length < this.size
							? (this.tag = false)
							: (this.tag = true);
						this.total = res.data.data.total;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		searchMore() {
			searchMore({
				order_type: 3,
				time_type: 5,
				key: this.$route.query.name,
				type: 2,
				page: 1,
				size: 25,
			})
				.then((res) => {
					if (res.code == 200) {
						this.flash_list = res.data.list;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.Main {
	padding-top: 60px;
}

.MainLeft {
	width: 792px;
	background-color: #fff;
	border-radius: 20px;
}

.MainRight {
	width: 360px;
}

.dexBox {
	width: 100%;
	height: 180px;
	background-image: url("https://zy.metaera.media/assets/images/dex/983.png");
	background-size: 100% 100%;
}

.dexval div:nth-child(1) {
	font-size: 38px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	margin-left: 40px;
}

.dexval div:nth-child(2) {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #d4d4d7;
	margin-left: 5px;
	position: relative;
	top: -10px;
}

.dexval {
	width: 1200px;
	margin: 0 auto;
	padding-top: 45px;
}

.dexHeader {
	padding-left: 20px;
	height: 60px;
	border-radius: 20px 20px 0 0;
	border-bottom: 1px solid #efefef;
}

.MainRight_6 {
	margin-top: 45px;
	width: 372px;
}

.MainRight_6_title {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #fff;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 8px 8px 0 0;
	padding: 16px 22px;
}
.headerActice {
	position: relative;
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	padding-left: 20px;
}

.headerActice::after {
	position: absolute;
	content: "";
	display: inline-block;
	width: 3px;
	height: 18px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 2px 2px 2px 2px;
	opacity: 0.85;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
}

.MainRight_1 {
	width: 100%;
	background: #ffffff;
	border-radius: 18px 18px 18px 18px;
	opacity: 1;
	height: 500px;
	overflow-y: auto;
}

.MainRight_2 {
	padding-left: 0;
	margin-top: 20px;
	padding-bottom: 40px !important;
}

.MainRight_2 .wzitem {
	height: 80px;
}

.MainRight_2 .wzitem img {
	width: 130px;
	height: 80px;
}

.MainRight_2 .wzitemL {
	height: 80px;
	margin-left: 14px;
}

.timeOut {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #9b9b9b;
}

.kxsteps {
	padding-left: 16px;
	margin-top: 20px;
}

.kxsteps .stepsVal {
	font-size: 15px;
	margin-top: 0;
}
@media screen and (max-width: 950px) {
	.Main {
		padding-top: 10px;
	}
	.MainRight {
		display: none;
	}
	.MainLeft {
		width: 90%;
		margin-left: 5%;
	}
	.dexval {
		width: 100%;
		margin: 0 auto;
		padding-top: 45px;
	}
}
</style>
<style>
.wzitemLImageNull {
	margin-left: 0px;
	width: 100%;
}
.wzitemImageNull {
	height: 100px;
}
.dexBoxm .wzitem {
	padding-bottom: 20px;
	border-bottom: 1px solid #efefef;
}

.dexBoxm .wzitem:nth-last-child(1) {
	border-bottom: none;
}
</style>
<style>
.dexWrapper .el-step__icon-inner {
	display: none;
}
.dexWrapper .steps .el-step__icon {
	width: 9px;
	height: 9px;
}
.dexWrapper .steps .el-step__icon.is-text {
	/* border-color: #000000; */
	/* background: #6974c8; */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	/* border: 4px solid; */
}
.dexWrapper .steps .el-step.is-vertical .el-step__line {
	left: 4px;
}
.dexWrapper .el-step__head.is-wait {
	color: #000000;
	border-color: #dedee1;
}
.dexWrapper .el-step__head.is-finish {
	color: #000000;
	border-color: #dedee1;
}
.dexWrapper .el-step__main {
	height: 99px;
}

.dexWrapper .el-step__description {
	padding-right: 0% !important;
}

.dexWrapper .btnitem:nth-last-child(1) {
	margin-left: 10px;
}
.dexWrapper .el-step__icon-inner {
	display: none;
}
.dexWrapper .el-step__icon {
	border: 2px solid !important;
	width: 8px;
	height: 8px;
	border-color: #000 !important;
}
</style>
